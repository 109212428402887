import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { ProjectContext, UserContext } from '@core/context';
import useEnvInfo from '@core/hooks/useEnvInfo';
import useIntercom from '@core/hooks/useIntercom';
import { useSuperHubStore } from '@core/store';

import HealthCheck from './HealthCheck';
import InactiveBanner from './InactiveBanner';
import StagingBanner from './StagingBanner';
import StagingDiscussionsBanner from './StagingDiscussionsBanner';
import SuggestedEditsPreview from './SuggestedEditsPreview';

function AppFooter({ isDetachedProductionSite }) {
  const { isProd } = useEnvInfo();

  const { project } = useContext(ProjectContext);
  const isSuperHubAdmin = useSuperHubStore(s => s.isSuperHubAdmin);
  const isActive = project?.is_active;
  const { pathname } = useLocation();
  const isPreview = pathname.includes('/preview') && pathname.includes('/suggested-edits/');
  const isDiscuss = pathname.startsWith('/discuss');
  const isStagingEnabled = project?.parent ? project.parent.flags.staging : project.flags.staging;

  // Disable intercom for SuperHub admins or ReadMe employees when in a prod environment
  const { is_god: isGod } = useContext(UserContext);
  const isEnabled = isSuperHubAdmin ? false : isProd ? !isGod : true;
  useIntercom({ isEnabled, useProjectIntercomId: true });

  return (
    <footer aria-label="Status banner" className="AppFooter rm-Banners">
      <HealthCheck planTrial={project.planTrial} />
      <InactiveBanner isActive={isActive} />
      {/* Present when viewing staged changes */}
      <StagingBanner isStage={!isDetachedProductionSite && isStagingEnabled} />
      {/* Present when viewing discussion pages on a staging site */}
      <StagingDiscussionsBanner isDiscuss={isDiscuss} isStage={!isDetachedProductionSite && isStagingEnabled} />
      <SuggestedEditsPreview isPreview={isPreview} />
    </footer>
  );
}

AppFooter.propTypes = {
  isDetachedProductionSite: PropTypes.bool,
};

export default AppFooter;
