import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import useSWR from 'swr';

import { APIBaseUrlContext } from '@core/context';
import usePlanPermissions from '@core/hooks/usePlanPermissions';
import { useProjectStore } from '@core/store';

import Banner from '@ui/Banner';

const fetcher = (...args) => fetch(...args).then(res => res.json());

const HealthCheck = ({ planTrial }) => {
  const healthCheck = useProjectStore(s => s.data.health_check);
  const apiBaseUrl = useContext(APIBaseUrlContext);
  const hasPermission = usePlanPermissions(planTrial, 'healthCheck');
  const hasProvider = !!hasPermission && !!healthCheck.provider;
  const path = hasProvider ? `${apiBaseUrl === '/' ? '' : apiBaseUrl}/api/health-check` : null;

  const { data, error } = useSWR(path, fetcher, { refreshInterval: 60000 });

  return (
    !error &&
    data !== undefined &&
    !data.healthy &&
    !data.error && (
      <Banner data-testid="health-check" icon="icon-warning">
        We’re currently having some issues with our infrastructure. Please check back soon to see if this has been
        resolved.&nbsp;
        {!!data.url && (
          <a data-testid="health-url" href={data.url}>
            Learn more
          </a>
        )}
      </Banner>
    )
  );
};

HealthCheck.propTypes = {
  /**
   * The plan (or the output of the various plan overrides) for the current project. Identifies whether the project permissions allow for the health check.
   */
  planTrial: PropTypes.string.isRequired,
};

export default React.memo(HealthCheck);
